import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image'; 
import { Box, Text, Flex, Image } from '@chakra-ui/react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import CTAButton from '../CTAButton';

function Collection(props) {
  const { collection, reverse, path } = props;
  
  const { coverImage, catColor, description, slug, name, parallaxImage, ctaRoute } = collection;
  
  const pl = reverse ? {base:'0', md:'2%', lg:'4%', xl:'120px'} : {base:'0', md:'10%', lg:'23%', xl:'243px'};
  const pr = reverse ? {base:'0', md:'10%', lg:'23%', xl:'243px'} : {base:'0', md:'2%', lg:'4%', xl:'120px'};

  return (
    <ParallaxProvider>
      <Flex
        w="100%"
        mb={{base:'50px', md:'198px', lg:'198px', xl:'198px'}}
        direction={{base:'column', md:reverse ? 'row-reverse' : 'row'}}
        alignItems={{base:'center', md:'normal'}}
        
      >
        {/* IMAGE */}
        <Flex
          maxW={{base:'389px', ms:'428px', md:'552px'}}
          maxH={{base:'418px', ms:'460px', md:'471px'}}
          position="relative"
        >
          <GatsbyImage image={coverImage.asset.gatsbyImageData} alt={'Product cover image'} />

          {/* PACKAGE IMAGE */}
          <Flex
            maxW={{base:'244px'}}
            maxH={{base:'260px'}}
            position="absolute"
            bottom={{base:'1%', md:'0'}}
            right={{
              base:!reverse ? '-7.5vw' : 'auto',
              md:!reverse ? '-7.5vw' : 'auto', 
              mds:!reverse ? '-7.5vw' : 'auto',
              lg:!reverse ? '-7.5vw' : 'auto',
              lgs:!reverse ? '-6.25vw' : 'auto',
              xl:!reverse ? '-90px' : 'auto',
            }}
            left={{
              base:reverse ? '-13.5vw' : 'auto', 
              md:reverse ? '-13.5vw' : 'auto', 
              mds:reverse ? '-13.5vw' : 'auto',
              lg:reverse ? '-13vw' : 'auto',
              lgs:reverse ? '-11.5vw' : 'auto',
              xl:reverse ? '-150px' : 'auto',
            }}
          >
            <Parallax styleInner={{transition: `${parallaxImage.velocity || 0.5}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[5, 30]} tagOuter='figure'>
              <Image 
                src={parallaxImage.image.asset.url}
                boxSize={{base:'160px', md:'224px', lg:'244px'}}
                objectFit="contain"
              />
            </Parallax>
          </Flex>
        </Flex>

        {/* TEXT */}
        <Flex
          direction="column"
          py={{base:'20px', ms:'30px', md:'77px'}}
          pl={pl}
          pr={pr}
        >
          {/* HEADER */}
          <Text
            maxW={{base:'100%', md:'287px'}}
            color="primary"
            fontSize={{base:'45px', md:'65px'}}
            fontWeight="800"
            letterSpacing="-0.5px"
            lineHeight="1"
            textTransform={slug.current === 'cbd' ? 'uppercase' : 'capitalize'}
            textAlign={{base:'center', md:'left'}}
            mx={{base:'auto', md:'0'}}
          >
            {`${collection.category ? name : name} ${collection.category ? collection.category.name : ''}`}
          </Text>

          {/* DESC */}
          <Text
            maxW={{base:'83%', ms:'78%', md:'287px'}}
            color="primary"
            fontSize={{base:'18px', md:'18px'}}
            fontWeight="600"
            letterSpacing="0"
            lineHeight="1.5"
            py={{base:'25px', md:'40px'}}
            textAlign={{base:'center', md:'left'}}
            mx={{base:'auto', md:'0'}}
          >
            {description ? description : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.'}
          </Text>

          {/* BUTTON */}
          <Box maxW={'170px'} minW={'152px'} mx={{base:'auto', md:'0'}}>
            <CTAButton
              href={ctaRoute.route}
              width={'100%'}
              height={{base:'38px', lg:'48px'}}
              fontSize={{base:'11px', md:'13px'}}
              fontWeight='900'
              bgColor={catColor.color ? catColor.color : 'greenMantis'}
              color={'white'}
              _hover={{ bg: 'primary'}}
              ctaText={`shop ${name}`}
            />
          </Box>
        </Flex>

      </Flex>
    </ParallaxProvider>
  );
};

export default Collection;
