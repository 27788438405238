import * as React from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import SeoComponent from '../components/SeoComponent';
import Hero from '../sections/Hero';
// import TextStrokeHeader from '../components/TextStrokeHeader';
import Collection from '../components/Collection';

/* IMPORT APP CONTEXT */
import { AppContext } from '../context';

function CollectionsPage(props) {
  const { pageContext } = props;

  const { seoTitle, seoDescription, seoImage, parallaxImgs, sections } = pageContext.pageData.data.sanityPage;
  const heroData = sections.find(item => item._type === 'hero');
  const seoImg = seoImage?.image || heroData?.heroImg?.bgImage;
  
  const [allMounted, setAllMounted] = React.useState<boolean | null>(null);

  const [collections, setCollections] = React.useState<any[] | null>(null);

  const appContext = React.useContext(AppContext);

  React.useEffect(() => {
    /* SCROLL TO TOP */
    appContext.pageTopRef.current.scrollIntoView();
    
    const coll = [] as any[];
    
    pageContext.categories.map((category) => {
      if (category.subcategories.length > 0) {
        coll.push(...category.subcategories);
      } else {
        coll.push(category);
      }
    });
    
    setCollections(coll);
    setAllMounted(true);
  }, []);

  return (
    <>
      <SeoComponent
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImg?.asset?.url}
      />
      
      <Box
        bgColor="grayBG"
        position="relative"
        overflow="hidden"
      >
        {/* HERO */}
        <Hero
          header={heroData.mainHeader.headerText}
          subheader={heroData.accentHeader.headerText}
          heroBgImg={heroData.heroImg} 
          curveColor={'#F1F0FA'}
        />

        {/* COLLECTIONS SECTION */}
        <ParallaxProvider>
          <Box
            maxW={{base:'90%', ms:'84%', mds:'84%', lg:'84%', xl:'1200px'}} 
            h="auto"
            mx="auto"
            display="flex"
            flexDirection="column"
            position="relative"
            pt={{base:'20px', ms:'30px', md:'60px'}}
            pb={{base:'50px', md:'50px', lg:'50px', xl:'52px'}}
          >
            {/* COLLECTIONS CONTAINER */}
            <Flex
              w={{base:'90%', ms:'75%', md:'100%', xl:'1200px'}}
              mx="auto"
              direction="column"
            >
              {/* HEADER */}
              {/* <TextStrokeHeader 
                text={'products'} 
                fontSize={{base:'53px', ms:'70px', md:'110px', lg:'155px', xl:'180px'}}
                // lineHeight={{base:'0.8', md:'0.8', lg:'0.9', xl:'165px'}}
              /> */}

              {/* COLLECTIONS */}
              {collections && (
                <Flex
                  w="100%"
                  direction="column"
                  style={{zIndex:2}}
                >
                  {collections.map((category, indx) => {
                    return (
                      <Collection 
                        key={indx}
                        collection={category}
                        reverse={indx % 2 !== 0 ? true : false}
                        marginTop={indx !== 0 ? {base:'50px', md:'80px', lg:'100px', xl:'198px'} : {base:'0px'}}
                        path={pageContext.productPagesPath}
                      />
                    );
                  })}

                </Flex>
              )}

            </Flex>

            {/* RIGHT TOP PARALLAX IMAGE */}
            <Flex
              maxW={{base:'30vw', ms:'25vw', md:'24.79vw', xl:'357px'}}
              maxH={{base:'30vw', ms:'25vw', md:'20.49vw', xl:'295px'}}
              position="absolute"
              top={{base:'-10vw', ms:'-10vw', md:'55vw', mds:'45vw', lg:'37vw', lgs:'27vw', xl:'370px'}}
              right={{base:'-17.36vw', ms:'-17.36vw', md:'-17.36vw', xl:'-250px'}}
              zIndex="3"
            >
              <Parallax styleInner={{transition: `${parallaxImgs[1]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                <Image 
                  src={parallaxImgs[1]?.image.asset.url}
                  boxSize={{base:'30vw', ms:'25vw', md:'24.79vw', xl:'357px'}}
                  objectFit="contain"
                />
              </Parallax>
            </Flex>

            {/* LEFT PARALLAX IMAGE */}
            <Flex
              maxW={{base:'20vw', md:'12.57vw', xl:'181px'}}
              maxH={{base:'20vw', md:'12.57vw', xl:'181px'}}
              position="absolute"
              top={{base:'300vw', ms:'230vw', md:'150vw', mds:'130vw', lg:'105vw', lgs:'90vw', xl:'1250px'}}
              left={{base:'-8.42vw', ms:'-8.42vw', md:'-10.42vw', xl:'-150px'}}
              zIndex="overlay"
            >
              <Parallax styleInner={{transition: `${parallaxImgs[0]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[30, -20]} tagOuter='figure'>
                <Image 
                  src={parallaxImgs[0]?.image.asset.url}
                  boxSize={{base:'20vw', md:'12.57vw', xl:'181px'}}
                  objectFit="contain"
                />
              </Parallax>
            </Flex>

            {/* BOTTOM PARALLAX IMAGE */}
            {allMounted && (
              <Flex
                maxW={{base:'20vw', md:'12.57vw', xl:'181px'}}
                maxH={{base:'20vw', md:'12.57vw', xl:'181px'}}
                position="absolute"
                bottom={{base:'170vw', ms:'120vw', md:'68vw', mds:'58vw', lg:'55vw', lgs:'49.61vw', xl:'700px'}}
                right={{base:'-8.42vw', ms:'-8.42vw', md:'-10.42vw', xl:'-150px'}}
                zIndex="overlay"
              >
                <Parallax styleInner={{transition: `${parallaxImgs[2]?.velocity || 6}s cubic-bezier(0.34, 1.56, 0.64, 1)`}} y={[32, -22]} tagOuter='figure'>
                  <Image 
                    src={parallaxImgs[2]?.image.asset.url}
                    boxSize={{base:'20vw', md:'12.57vw', xl:'181px'}}
                    objectFit="contain"
                  />
                </Parallax>
              </Flex>
            )}

          </Box>
        </ParallaxProvider>

        {/* SVG BOTTOM IMAGE */}
        <SvgBottom />
      </Box>
    </>
  );
}

const SvgBottom = () => {

  return (
    <Box
      w="100%"
      h="5%"
      position="absolute"
      bottom="0"
      left="0"
      style={{zIndex:0}}
    >
      <svg viewBox="0 0 500 149" preserveAspectRatio="none" style={{width:'100%', height:'100%'}}>
        <path
          fill="white"
          d="M-0.84,64.63 C149.99,150.00 326.46,132.72 501.41,65.63 L500.00,150.00 L0.00,150.00 Z"
        >
        </path>
      </svg> 
    </Box>
  );
};

export default CollectionsPage;
